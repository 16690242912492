import React from 'react';

import { data } from 'services';
import { Wrapper, Description, MainInfo, Label, Property, AdditionalInfo, CloseButton, Scroller } from 'libs/ui';
import { getDateTime, getRackName } from 'services/types';

const InfoBlock = (props) => {
  const { info } = props;
  //console.log('🚀 ~ InfoBlock ~ info', info);

  if (!info) {
    return null;
  }

  return (
    <Wrapper>
      <Description>
        {info.message ? info.message : ''}
        <CloseButton onClick={props.close} />
      </Description>
      <Scroller>
        <MainInfo>
          <Property>
            <Label>Время</Label>
            <span>{getDateTime(info.timestamp)}</span>
          </Property>
          <Property>
            <Label>Источник</Label>
            <span>{data.getDictValue('events.source_type', info.source_type)}</span>
          </Property>
          <Property>
            <Label>№</Label>
            <span>{getRackName(info.source)}</span>
          </Property>
          <Property>
            <Label>Приоритет</Label>
            <span>{data.getDictValue('events.type', info.type)}</span>
          </Property>
        </MainInfo>
        <AdditionalInfo>
          <Label>Дополнительно</Label>
          <pre>
            <br />
            {JSON.stringify(info, null, 2)}
          </pre>
        </AdditionalInfo>
      </Scroller>
    </Wrapper>
  );
};

export default InfoBlock;

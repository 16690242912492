import { useState } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';

import { addPlate, updatePlate, getClientsDict } from 'services/payments-service';
import { getCategoriesList, getOptionsList } from 'services/types';
import { Button, themes, Popup2 } from 'libs/ui';
import { DictInput, ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg } from 'libs/form';
import { StyledButtonContainer } from './plate-form.styled';

const PlateForm = (props) => {
  const { error, destroy, refresh, setMessage, form, handleSubmit, close, type, clientId, id, initialValues, tariffs } = props;
  const isAdd = type === 'addForm';
  const [blacklist, setBlacklist] = useState(initialValues.is_blocked === true);
  // console.log('🚀 ~ PlateForm ~ initialValues:', initialValues);

  /**
   * after submit
   */
  const afterSubmit = () => {
    refresh();
    setMessage('Изменения сохранены');
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    //console.log('🚀 ~ submit ~ values:', values);
    // data
    const test = false;
    const out = { ...values };
    out.is_blocked = out.is_blocked === 'true' || out.is_blocked === true;
    out.vehicle_category = blacklist ? null : out.vehicle_category ? parseInt(out.vehicle_category) : null;
    if (!out.client_id && clientId) {
      out.client_id = clientId;
    }
    if (!clientId && !out.client_id) {
      out.client_id = null;
      out.client_name = null;
    }
    if (out.is_blocked) {
      out.start_at = null;
      out.end_at = null;
    }

    if (!isAdd) {
      out.id = id;
    }
    if (test) {
      console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addPlate : updatePlate;
    return method(out)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
      });
  };

  const cancel = () => {
    close();
    destroy(form);
  };

  const getClients = (name) => {
    return getClientsDict({
      filter_params: { name },
      sort_params: [{ field_name: 'name', direction: 'asc' }],
      paging_params: { limit: 5, offset: 0 },
    });
  };

  const title = isAdd ? 'Добавить СО' : 'Изменить СО';
  const dateDisabled = blacklist;
  const categoryDisabled = blacklist;

  return (
    <Popup2 w={482}>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />
        <ParkingFilter2 />

        {!clientId && (
          <WhiteStyledField
            selectedName={initialValues.client_name}
            label="Клиент"
            placeholder="Клиент"
            type="autocomplete2"
            name="client_id"
            getList={getClients}
          />
        )}

        {isAdd && <WhiteStyledField type="text" label="Госномер" placeholder="А000AA 000 rus" name="mean_id" validate={required} />}
        <WhiteStyledField type="text" label="Инициатор" placeholder="Введите текст" name="owner" validate={required} />
        <WhiteStyledField type="text" label="Причина внесения" placeholder="Введите текст" name="comment" validate={required} />

        <WhiteStyledField type="select" label="Категория" name="vehicle_category" disabled={categoryDisabled}>
          <option value="">Не задано</option>
          {getCategoriesList()}
        </WhiteStyledField>

        <WhiteStyledField
          disabled={dateDisabled}
          className="shortfield"
          type="dateTime"
          label="Дата действия с"
          placeholder="Дата действия с"
          name="start_at"
          //validate={required}
        />

        <WhiteStyledField
          disabled={dateDisabled}
          className="shortfield"
          type="dateTime"
          label="Дата действия по"
          placeholder="Дата действия по"
          name="end_at"
          //validate={required}
        />

        <DictInput dict="plates.list" onChange={(event) => setBlacklist(event.target.value === 'true')} />

        <WhiteStyledField className="shortfield" type="select" label="Тариф" name="tariff_id">
          <option value="">Не задано</option>
          {getOptionsList(tariffs)}
        </WhiteStyledField>

        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            Отменить
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    </Popup2>
  );
};

export default reduxForm({
  form: 'clientPlateForm',
  destroyOnUnmount: false,
})(PlateForm);

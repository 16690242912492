import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { user } from 'services';
import Rfid from './Row';
import RfidForm from './Window';
import Warning from 'modules/components/warnings';
import { Button, ButtonContainer, themes, Total, TableHeader, GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { getRfidTariffList } from 'store/ducks/models/tariffs';

const RfidTableHeader = styled(TableHeader)`
  color: #b7babf;
  opacity: 1;
  border-bottom: 1px solid #6c7277;
`;

class RfidGrid extends Component {
  getRfidList = () => {
    const rfids = this.props.entries;
    let rfidList = [];
    if (rfids) {
      for (let key in rfids) {
        rfidList.push(
          <Rfid
            type={this.props.type}
            reset={this.props.reset}
            key={rfids[key].id}
            setWarning={this.props.setWarning}
            removeWarning={this.props.removeWarning}
            cardForm={this.props.cardForm}
            getEntries={this.props.getEntries}
            addComment={this.props.addComment}
            changeStatus={this.props.changeStatus}
            setFormInfo={this.props.setFormInfo}
            removeFormInfo={this.props.removeFormInfo}
            vehicleClasses={this.props.vehicleClasses}
            {...rfids[key]}
          />
        );
      }
      return rfidList;
    }
  };
  showForm = (type) => {
    this.props.setFormInfo({
      type: type,
      data: {
        rfid: '',
        owner: '',
        vehicle_class: '',
        vehicle_category: '',
        vehicle_plate: '',
        tariff_id: '',
        comment: '',
      },
    });
  };
  getRfidForm = () => {
    const cardForm = this.props.cardForm;
    //console.log('🚀 ~ this.props', this.props);
    if (cardForm && (cardForm.type === 'addForm' || cardForm.type === 'editForm')) {
      return (
        <RfidForm
          type={cardForm.type}
          id={cardForm.id}
          clientId={this.props.clientId}
          addRfid={this.props.addRfid}
          updateRfid={this.props.updateRfid}
          getEntriesCount={this.props.getEntriesCount}
          removeFormInfo={this.props.removeFormInfo}
          setFormData={this.props.setFormData}
          getEntries={this.props.getEntries}
          data={cardForm.data}
          epmCategories={this.props.epmCategories}
          vehicleClasses={this.props.vehicleClasses}
          getTariffList={this.props.getRfidTariffList}
        />
      );
    }
  };

  render() {
    const rightAdd = user.haveRight('rfid.add');

    return (
      <GridBox>
        <TopRow>
          <Total white cnt={this.props.count} />
          <ButtonContainer>
            {rightAdd && (
              <Button theme={themes.blue} onClick={() => this.showForm('addForm')}>
                <i className="fa fa-plus" />
                Добавить RFID
              </Button>
            )}
          </ButtonContainer>
        </TopRow>
        <Grid className={this.props.className}>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <RfidTableHeader width="140px">Парковка</RfidTableHeader>}
              <RfidTableHeader width="100px">RFID</RfidTableHeader>
              <RfidTableHeader width="110px">Статус</RfidTableHeader>
              <RfidTableHeader width="125px">Водитель</RfidTableHeader>
              <RfidTableHeader width="215px">Категория ТС</RfidTableHeader>
              <RfidTableHeader width="165px">Класс ТС</RfidTableHeader>
              <RfidTableHeader width="95px">ГРЗ</RfidTableHeader>
              <RfidTableHeader width="185px">Тариф</RfidTableHeader>
              <RfidTableHeader>Комментарий</RfidTableHeader>
              <RfidTableHeader width="80px" />
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getRfidList()}</tbody>
        </Grid>
        {this.getRfidForm()}
        {this.props.interface.warning ? (
          <Warning
            width="540px"
            header="Блокировка RFID метки"
            mainText={`Блокированные RFID метки не дают право проезда на парковку. Вы уверены что хотите заблокировать RFID №${this.props.interface.warning.rfid}?`}
            cancelButton={{
              func: this.props.removeWarning,
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => {
                this.props.changeStatus(this.props.interface.warning.reqInfo);
                this.props.removeWarning();
              },
              text: 'Заблокировать RFID',
              img: '/images/icon_lock.png',
              theme: themes.red,
            }}
          />
        ) : null}
      </GridBox>
    );
  }
}

export default connect(null, {
  getRfidTariffList,
})(withRouter(RfidGrid));

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { getPageConfig } from 'services/grid-service';
import { ResetBtn, UseBtn } from 'libs/ui';
import {
  DictFilter,
  Input,
  Container,
  ButtonsContainer,
  DoubleField,
  DateSelect,
  DateTime,
  Multiselect,
  ZoneInput,
  Toggle,
  ParkingFilter,
} from '../index';
import zones from 'services/zones';

// {type: 'input', w: 160, name: 'id', title: 'ID', placeholder: '3786467864675656'},
// {type: 'dict', w: 140, dict: 'clients.status',},

export const Filters = (props) => {
  const { items, page, handleSubmit, reset, theme } = props;
  const pageConfig = getPageConfig(page);
  const activeFilters = {};
  pageConfig?.filters?.forEach((el) => {
    activeFilters[el.name] = el.active;
  });
  const formRef = useRef(null);

  /**
   * get element component
   * @param {*} type
   * @returns
   */
  const getComponent = (type) => {
    switch (type) {
      case 'dict':
        return DictFilter;

      default:
        return Input;
    }
  };

  /**
   * get attributes for type
   * @param {*} other
   * @param {*} type
   * @returns
   */
  const getAttr = (other, type) => {
    const out = {};
    const t = {
      input: {
        title: 'label',
        w: 'width',
      },
      zones: {
        w: 'width',
      },
      dict: {},
    };

    Object.keys(other).forEach((fn) => {
      let val = other[fn];
      const name = (t[type] && t[type][fn]) ?? fn;
      if (type !== 'dict' && fn === 'w') {
        val += 'px';
      }
      out[name] = val;
    });

    if (type === 'input' && !out['type']) {
      out['type'] = 'text';
    }

    return out;
  };

  /**
   * get double date input
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getDoubleDate = (key, attr) => {
    const { name, title, ...rest } = attr;
    const fn1 = name + '_from';
    const fn2 = name + '_to';
    return (
      <DateSelect key={key} from_name={fn1} to_name={fn2} {...rest}>
        <DoubleField>
          <DateTime width="160px" name={fn1} type="dateTime" label={title} withoutIcon />
          <DateTime width="160px" name={fn2} type="dateTime" withoutIcon />
        </DoubleField>
      </DateSelect>
    );
  };

  /**
   * get mean double
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getMeanDouble = (key, attr) => {
    const { name, title, dict, w, placeholder, ...rest } = attr;
    return (
      <DoubleField key={key}>
        <DictFilter dict={dict} />
        <Input width={w} name={name} type="text" label={title} placeholder={placeholder} {...rest} />
      </DoubleField>
    );
  };

  /**
   * get toggle
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getToggle = (key, attr) => {
    const { name, title, dict, w, placeholder, ...rest } = attr;
    return (
      <span className="center" key={key} {...rest}>
        <Toggle label={title} name={name} type="toggle" />
      </span>
    );
  };

  /**
   * get duration
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getDuration = (key, attr) => {
    const { name, title, ...rest } = attr;
    const fn1 = name + '_from';
    const fn2 = name + '_to';
    return (
      <DoubleField key={key} {...rest}>
        <Input width="110px" name={fn1} type="text" label={title} mask="99д 99ч 99м" storeWithMask placeholder="От" />
        <Input width="110px" name={fn2} type="text" mask="99д 99ч 99м" storeWithMask placeholder="До" />
      </DoubleField>

      // <DateTime width="160px" name={fn1} type="dateTime" label={title} withoutIcon />
      // <DateTime width="160px" name={fn2} type="dateTime" withoutIcon />
    );
  };

  /**
   * get double number
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getDoubleNumber = (key, attr) => {
    const { name, title, ...rest } = attr;
    const fn1 = name + '_from';
    const fn2 = name + '_to';
    return (
      <DoubleField key={key} {...rest}>
        <Input width="80px" name={fn1} type="text" label={title} naturalNumber placeholder="От" />
        <Input width="80px" name={fn2} type="text" naturalNumber placeholder="До" />
      </DoubleField>
    );
  };

  /**
   * get zones
   * @param {*} key
   * @param {*} attr
   * @returns
   */
  const getZones = (key, attr) => {
    const { title, ...rest } = attr;
    const zonesVals = zones.getZones();
    return (
      <Multiselect key={key} type="multiselect" label={title} additionalComponent={ZoneInput} data={zonesVals} countDisplayedIcons={5} {...rest} />
    );
  };

  /**
   * get filter items
   * @returns
   */
  const getItems = () => {
    const isActive = (el) => {
      let test = el.dict || el.name;
      if (!test) {
        return true;
      }
      if (!activeFilters) {
        return true;
      }
      test = test.split('.');
      test = test.length > 1 ? test[1] : test[0];
      if (activeFilters[test] === undefined) {
        return true;
      }
      return activeFilters[test];
    };
    const filtered = items.filter((el) => isActive(el));
    // console.log('🚀 ~ getItems ~ filtered:', filtered, active);
    const cnt = filtered.length;
    return filtered.map((el, idx) => {
      const { type, ...other } = el;
      const attr = getAttr(other, type);

      switch (type) {
        case 'zones':
          return getZones(idx, attr);
        case 'doubledate':
          return getDoubleDate(idx, attr);
        case 'duration':
          return getDuration(idx, attr);
        case 'meandouble':
          return getMeanDouble(idx, attr);
        case 'doublenumber':
          return getDoubleNumber(idx, attr);
        case 'toggle':
          return getToggle(idx, attr);
        case 'parking':
          return <ParkingFilter key={idx} {...attr} />;
        default:
          if (cnt === idx + 1) {
            attr.lastField = true;
          }

          const Cmp = getComponent(type);
          return <Cmp key={idx} {...attr} />;
      }
    });
  };

  const itemsEl = getItems();
  if (!itemsEl.length) {
    return null;
  }

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit} ref={formRef}>
        {itemsEl}
        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

Filters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  page: PropTypes.string.isRequired,
};

import styled, { css } from 'styled-components';

export const GridCell = styled.td`
  position: relative;
  text-align: ${(props) => (props.$number ? 'end' : props.align ? props.align : 'start')};
  padding-right: ${(props) => (props.$number ? '35px' : props.$pr10 ? '15px' : '5px')};
  border-bottom: 1px solid #d7d7d7;
  font-size: 15px;
  color: #000;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) =>
    props.$fullh &&
    css`
      height: auto;
      overflow: visible;
      padding-top: 3px;
      padding-bottom: 3px;
      white-space: normal;
    `}
  &::before {
    display: ${(props) => (props.status ? 'block' : 'none')};
    position: absolute;
    left: 5px;
    content: ${(props) => {
      if (props.status === 'active') return '';
      if (props.status === 'blocked' || props.status === 'archive' || props.status === 'blocked_by_client') return 'url(/images/icon_lock_gray.png)';
    }};
  }
  .toclip {
    opacity: 0;
  }
  &:hover .toclip {
    opacity: 1;
  }
  .pad-left {
    padding-left: 20px;
  }

  ${(props) =>
    props.theme === 'dark' &&
    css`
      border-bottom-color: #6c7277;
    `}
`;

export const OptionsCell = styled(GridCell)`
  overflow: visible;
  padding-top: 4px;
`;

export const GridCellLock = styled(GridCell)`
  &::before {
    position: absolute;
    left: 5px;
    content: ${(props) => {
      if (props.status === 'active') return '';
      if (props.status === 'blocked' || props.status === 'archive' || props.status === 'blocked_by_client') return 'url(/images/icon_lock_gray.png)';
    }};
  }
`;

export const ToClip = styled.button`
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: 50%;
  transition: 0.2s all ease-in;
  margin: -12px 0 0 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #fff url('/images/clip.png') no-repeat 50% 50%;
  border: 1px solid #aaa;
  background-size: contain;
  z-index: 10;
  &:hover {
    border-color: #000;
    background-color: #f0f0f0;
  }
`;

import jsSHA from 'jssha';

import { user } from 'services';
import { api } from 'api';

const UPDATE_STATUS = '/pages/client/card/UPDATE_STATUS';
const SET_CLIENT_INFO = '/pages/client/card/SET_CLIENT_INFO';
const REMOVE_CLIENT_INFO = '/pages/client/card/REMOVE_CLIENT_INFO';
const UPDATE_CONTACTS = '/pages/client/card/UPDATE_CONTACTS';
const CLEAR_ENTRIES = '/pages/client/card/CLEAR_ENTRIES';
const ADD_RFIDS = '/pages/client/card/ADD_STAYS';
const SET_OFFSET = '/page/client/card/SET_OFFSET';
const SET_RFIDS_COUNT = '/pages/client/card/SET_RFIDS_COUNT';
const SET_SORT_PARAMS = '/pages/client/card/SET_SORT_PARAMS';
const SET_NEW_CONTACT_FACE_DATA = '/pages/client/card/SET_NEW_CONTACT_FACE_DATA';
const SET_TYPE_OF_NEW_CLIENT = '/pages/client/card/SET_TYPE_OF_NEW_CLIENT';

export default function reducer(state, action) {
  const defaultState = {
    clientInfo: {},
    typeOfNewClient: null,
    newContactFace: null,
    rfids: {},
    offset: 0,
    count: 0,
    sortParams: [],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case SET_TYPE_OF_NEW_CLIENT:
      return {
        ...state,
        typeOfNewClient: action.data,
      };
    case SET_CLIENT_INFO:
      return {
        ...state,
        clientInfo: action.data,
      };
    case REMOVE_CLIENT_INFO:
      return {
        ...defaultState,
      };
    case UPDATE_CONTACTS:
      return {
        ...state,
        clientInfo: {
          ...state.clientInfo,
          contact: action.data,
        },
      };
    case UPDATE_STATUS:
      var id1 = action.id.replace('id-', '');
      //console.log('111111', state.rfids, id1, action);
      return {
        ...state,
        rfids: {
          ...state.rfids,
          [id1]: {
            ...state.rfids[id1],
            status: action.status,
            is_blocked: action.status !== 'active',
            comment: action.comment,
          },
        },
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        rfids: {},
      };
    case ADD_RFIDS:
      return {
        ...state,
        rfids: {
          ...state.rfids,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_RFIDS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_NEW_CONTACT_FACE_DATA:
      return {
        ...state,
        newContactFace: action.data,
      };
    default:
      return state;
  }
}

export const updateStatus = (id, status, comment) => {
  return {
    type: UPDATE_STATUS,
    id: `id-${id}`,
    status: status,
    comment: comment,
  };
};
export const setClientInfo = (info) => {
  return {
    type: SET_CLIENT_INFO,
    data: info,
  };
};
export const setTypeOfNewClient = (type) => {
  return {
    type: SET_TYPE_OF_NEW_CLIENT,
    data: type,
  };
};
export const removeClientInfo = () => {
  return {
    type: REMOVE_CLIENT_INFO,
  };
};
export const setClientContact = (info) => {
  return {
    type: UPDATE_CONTACTS,
    data: info,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_RFIDS_COUNT,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const addRfids = (rfids) => {
  return {
    type: ADD_RFIDS,
    data: rfids,
  };
};
export const setNewContactFaceData = (info) => {
  return {
    type: SET_NEW_CONTACT_FACE_DATA,
    data: info,
  };
};

const formatClientInfo = (info) => {
  let formatInfo = {};
  for (let item in info) {
    if (info[item] === null) {
      formatInfo[item] = '';
    } else {
      formatInfo[item] = info[item];
    }
    formatInfo[item] = info[item];
  }
  return formatInfo;
};

export const getClientInfo = (id) => (dispatch) => {
  return api
    .call('clients.view', {
      id: id,
    })
    .then((res) => {
      const clientInfo = formatClientInfo(res);
      dispatch(setClientInfo(clientInfo));
    });
};

/**
 * update client
 * @param {*} params
 * @returns
 */
export const updateClientInfo = (params) => (dispatch) => {
  const useLogin = user.canUserLogin();
  let password = params.info.lkpwd;
  if (useLogin) {
    delete params.info.lkpwd;
  }

  return new Promise((resolve, reject) => {
    // update client
    api
      .call('clients.update', {
        id: params.id,
        item: params.info,
      })
      .then((data) => {
        if (!useLogin) {
          const clientInfo = formatClientInfo(data);
          dispatch(setClientInfo(clientInfo));
          resolve(clientInfo);
          return;
        }

        // update password
        const out = { client_id: params.id, unlimited: params.info.lk_unlimited ? params.info.lk_unlimited : false };
        if (password) {
          const shaObj = new jsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' });
          shaObj.update(password);
          const encoded = shaObj.getHash('HEX');
          out.password = encoded;
        }
        api
          .call('clients.lk_add', out)
          .then((lk) => {
            let clientInfo = formatClientInfo(data);
            clientInfo.data.lk_login = lk;
            dispatch(setClientInfo(clientInfo));
            resolve(clientInfo);
          })
          .catch((error) => {
            reject(error.message.toString());
          });
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};

export const changeClientStatus = (params, callback) => (dispatch) => {
  return api.call('clients.change_status', params).then((res) => {
    const clientInfo = formatClientInfo(res);
    dispatch(setClientInfo(clientInfo));
    if (callback) {
      callback();
    }
  });
};

export const updateClientContact = (params, callback) => (dispatch, getState) => {
  return api
    .call('clients.update_contact', {
      id: params.id,
      item: params.info,
    })
    .then((res) => {
      dispatch(setClientContact(res));
      if (callback) {
        callback(res);
      }
    });
};

export const addClientContact = (params, callback) => (dispatch, getState) => {
  return api
    .call('clients.add_contact', {
      client_id: params.client_id,
      item: params.info,
    })
    .then((res) => {
      dispatch(setClientContact(res));
      if (callback) {
        callback(res.id);
      }
    });
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      decorateParams[key] = filter[key];
    }
  }
  return {
    ...decorateParams,
    client_id: getState().client.clientInfo.id,
  };
};

export const changeRfidStatus = (params) => (dispatch) => {
  return api
    .call('rfid.change_status', {
      item: params,
    })
    .then((res) => {
      dispatch(updateStatus(res.id, res.status, res.comment));
      if (callback) {
        callback(res.id);
      }
    });
};

export const addClient = (params, callback) => (dispatch) => {
  return api
    .call('clients.add', {
      item: params.info,
    })
    .then((res) => {
      const clientInfo = formatClientInfo(res);
      callback && callback(res.id);
      dispatch(setClientInfo(clientInfo));
    });
};

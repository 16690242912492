import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getRequestParams,
  addClients,
  clearEntries,
  setSortParams,
  setOffset,
  setCount,
  //getUploadData
} from 'store/ducks/models/clients';
import { setTypeOfNewClient } from 'store/ducks/models/client';
import { setImportErrors, sendFileContent, clearErrors } from 'store/ducks/models/processing';
import { setScroll } from 'store/ducks/interface/clientsGrid';
import { Controller } from 'libs/control';
import { Headline } from 'modules/components/pageComponents';
import { GridContainer, GridSetup } from 'libs/ui';
import ClientsFilter from './Filter';
import ClientsGrid from './Grid';

const Clients = (props) => {
  const { t } = useTranslation();

  // grid config
  const page = 'clients';
  const [rerender, setRerender] = useState(1);

  return (
    <>
      <Headline $mb $alignLeft>
        {t('sections.Клиенты')}
        {/* <GridSetup page={page} onChange={() => setRerender((val) => val + 1)} /> */}
      </Headline>
      <GridContainer>
        <Controller
          type={page}
          rerender={rerender}
          gridFilter={ClientsFilter}
          grid={ClientsGrid}
          entries={props.clients}
          filter={props.filter}
          offset={props.offset}
          interface={props.interface}
          count={props.count}
          sortParams={props.sortParams}
          importErrors={props.importErrors}
          uploadData={props.uploadData}
          //methods
          setTypeOfNewClient={props.setTypeOfNewClient}
          getRequestParams={props.getRequestParams}
          addEntries={props.addClients}
          clearEntries={props.clearEntries}
          setOffset={props.setOffset}
          setSortParams={props.setSortParams}
          setCount={props.setCount}
          setScroll={props.setScroll}
          clearErrors={props.clearErrors}
          sendFileContent={props.sendFileContent}
          //getUploadData={props.getUploadData}
          setImportErrors={props.setImportErrors}
        />
      </GridContainer>
    </>
  );
};
const mapStateToProps = (store) => {
  return {
    clients: store.clients.clients,
    offset: store.clients.offset,
    interface: store.clientsGrid,
    count: store.clients.count,
    uploadData: store.clients.uploadData,
    sortParams: store.clients.sortParams,
    importErrors: store.processing.importErrors,
  };
};

export default connect(mapStateToProps, {
  setTypeOfNewClient,
  addClients,
  clearEntries,
  setScroll,
  setOffset,
  setSortParams,
  setCount,
  getRequestParams,
  clearErrors,
  sendFileContent,
  setImportErrors,
  //getUploadData
})(Clients);

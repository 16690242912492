import { api } from 'api';

const SET_TARIFF_INFO = '/pages/options/tariffs/SET_TARIFF_INFO';
const REMOVE_TARIFF_INFO = '/pages/options/tariffs/REMOVE_TARIFF_INFO';

export default function reducer(state, action) {
  const defaultState = {
    info: null,
  };
  if (!state) {
    state = defaultState;
  }
  switch (action.type) {
    case SET_TARIFF_INFO:
      return {
        ...state,
        info: action.data,
      };
    case REMOVE_TARIFF_INFO:
      return {
        ...state,
        info: null,
      };
    default:
      return state;
  }
}

export const setTariffInfo = (info) => {
  return {
    type: SET_TARIFF_INFO,
    data: info,
  };
};
export const removeTariffInfo = () => {
  return {
    type: REMOVE_TARIFF_INFO,
  };
};

export const getTariffInfo = (id) => (dispatch) => {
  return api.call('tariff.view', { id }).then((res) => {
    const decorateData = decorateDataBeforeStoring(res);
    dispatch(setTariffInfo(decorateData));
  });
};

export const addTariff = (params, callback) => (dispatch, getState) => {
  return new Promise((resolve) => {
    api
      .call('tariff.add', params)
      .then((res) => {
        const decorateData = decorateDataBeforeStoring(res);

        dispatch(setTariffInfo(decorateData));

        if (callback) {
          callback(res.id);
        }
      })
      .catch((error) => {
        // console.log('🚀 ~ returnnewPromise ~ error:', error);
        resolve(error.data ? error.data : error);
      });
  });
};

export const toArchive = (id, callback) => (dispatch, getState) => {
  return api.call('tariff.change_status', { id: id, status: 'archive' }).then((res) => {
    const decorateData = decorateDataBeforeStoring(res);
    dispatch(setTariffInfo(decorateData));
    if (callback) {
      callback();
    }
  });
};

export const updateTariff = (tariffId, params, callback) => (dispatch) => {
  const paramsDecorated = Object.assign({}, params, { id: tariffId });
  //console.log(paramsDecorated); return;
  return new Promise((resolve) => {
    return api
      .call('tariff.update', paramsDecorated)
      .then((res) => {
        if (callback) {
          callback(res.id);
        }
      })
      .catch((error) => {
        resolve(error.data);
      });
  });
};

const decorateDataBeforeStoring = (data) => {
  const secFields = ['secondentry_interval', 'secondentry_duration', 'secondentry_period'];
  const decorateInfo = { ...data };
  const { time_to_leave, zones } = decorateInfo;
  if (time_to_leave && time_to_leave % 60 === 0) {
    decorateInfo.time_to_leave = time_to_leave / 60;
  }

  // sec to min
  if (decorateInfo.data) {
    secFields.forEach((fn) => {
      if (decorateInfo.data[fn] !== undefined) {
        decorateInfo[fn] = Math.round(decorateInfo.data[fn] / 60);
        decorateInfo.data[fn] = decorateInfo[fn];
      }
    });
  }

  if (zones) {
    for (let zone in zones) {
      if (Array.isArray(zones[zone].periods)) {
        zones[zone].periods.forEach((period) => {
          if (period.start_at && period.start_at % 60 === 0) period.start_at = period.start_at / 60;
          if (period.period && period.period % 60 === 0) period.period = period.period / 60;
          if (period.amount && period.amount % 100 === 0) period.amount = period.amount / 100;
        });
      }
    }
  }

  return decorateInfo;
};

import React from 'react'
import { connect } from 'react-redux'

import { GridContainer } from 'libs/ui'
import { Controller } from 'libs/control'
import Grid from './Grid'

import {
    getRequestParams,
    addCategories,
    clearEntries,
    setSortParams,
    setCategoryInfo,
    addCategory,
    updateCategory,
    setOffset
} from 'store/ducks/models/categories'
import {
    setScroll,
    addFormDisplayHandler,
    updateFormDisplayHandler,
    updateMessageDisplayHandler
} from 'store/ducks/interface/categoriesGrid'

const Categories = props => {
    return (
        <GridContainer>
            <Controller
                type='vehicle_category'
                grid={Grid}
                offset={props.offset}
                entries={props.categories}
                getRequestParams={props.getRequestParams}
                addEntries={props.addCategories}
                clearEntries={props.clearEntries}
                addCategory={props.addCategory}
                updateCategory={props.updateCategory}
                setCategoryInfo={props.setCategoryInfo}
                categoryInfo={props.categoryInfo}
                addFormDisplayHandler={props.addFormDisplayHandler}
                updateFormDisplayHandler={props.updateFormDisplayHandler}
                updateMessageDisplayHandler={props.updateMessageDisplayHandler}
                setSortParams={props.setSortParams}
                setOffset={props.setOffset}
                setScroll={props.setScroll}
                sortParams={props.sortParams}
                interface={props.interface}
            />
        </GridContainer>
    )
}

const mapStateToProps = (store) => {
    return {
        categories: store.categories.categories,
        offset: store.categories.offset,
        sortParams: store.categories.sortParams,
        categoryInfo: store.categories.categoryInfo,
        interface: store.categoriesGrid
    }
}

export default connect(mapStateToProps, {
    getRequestParams,
    addCategories,
    clearEntries,
    setSortParams,
    setCategoryInfo,
    addCategory,
    updateCategory,
    setOffset,
    setScroll,
    addFormDisplayHandler,
    updateFormDisplayHandler,
    updateMessageDisplayHandler
})(Categories)
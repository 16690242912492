import { data } from 'services';
import { getDate } from 'services/types';
import { ParkingCell, StatusCell } from 'libs/ui';

// get row class
const getRowClass = (row) => {
  let out = '';
  out += ' ' + row.status;
  return out;
};

const getFields = (props) => {
  const fields = [
    { name: 'mean_id', title: 'ГРЗ', width: 170, sortField: 'mean_id' },
    { name: 'owner', title: 'Инициатор', sortField: 'owner' },
    { name: 'comment', title: 'Причина внесения', width: 360 },
    { name: 'start_at', title: 'Дата с', width: 210, sortField: 'start_at', render: (val) => getDate(val) },
    { name: 'end_at', title: 'Дата по', width: 210, sortField: 'end_at', render: (val) => getDate(val) },
    {
      name: 'is_blocked',
      title: 'Список',
      width: 120,
      renderCell: true,
      style: { paddingLeft: '15px' },
      render: (val, other) => {
        const title = data.getDictValue('plates.list', val);
        return (
          <StatusCell {...other} status={!val ? 'active' : 'blocked'}>
            {title}
          </StatusCell>
        );
      },
    },
  ];

  if (window.config.central) {
    fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
  }

  if (!props.clientId) {
    fields.push({ name: 'client_name', title: 'Клиент', width: 145 });
  }
  return fields;
};

export const tableParams = { getFields, getRowClass, withComment: true };

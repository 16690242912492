import styled, { css } from 'styled-components';

export const TableHeadCell = styled.th`
  width: ${(props) => props.width};
  text-align: ${(props) => (props.$numeric ? 'end' : 'start')};
  padding-right: ${(props) => (props.$numeric ? '35px' : '0')};
  position: relative;
  border-bottom: 1px solid #929292;
  height: 35px;
  font-size: 13px;
  color: #1d2c3a;
  opacity: 0.5;
  &:nth-child(1) {
    padding-left: 20px;
  }

  ${(props) =>
    props.withSort &&
    css`
      pointer-events: auto;
      &:hover {
        font-weight: bold;
        opacity: 1;
      }
      div {
        cursor: pointer;
        p {
          display: inline-block;
          margin-right: 10px;
        }
      }
    `}

  ${(props) =>
    props.theme === 'dark' &&
    css`
      color: #b7babf;
      opacity: 1;
      border-bottom: 1px solid #6c7277;
    `}

  & > div {
    display: flex;
    align-items: center;
  }
`;

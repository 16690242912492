import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { config } from 'config';
import { GridCell, ToClip } from './cell.styled';
import { setParameters, resetParameters } from 'store/ducks/interface/widgetPopup';

class CellCmp extends React.Component {
  constructor() {
    super();

    this.state = { overflow: false };

    this.cell = null;
    this.hoverDelay = null;
    /*
        this.setCellRef = element => {
            console.log('set cell')
            this.cell = element;
        }
        */
    this.el = React.createRef();
  }

  componentDidMount() {
    const el = this.el.current;
    this.setState({ overflow: el.scrollWidth > el.clientWidth });
  }

  mouseEnter = () => {
    //console.log('overflow', this.state.overflow);
    if (this.state.overflow) {
      this.hoverDelay = setTimeout(this.setWidgetParams, 500);
    }
  };

  setWidgetParams = () => {
    if (this.el && this.el.current) {
      const rect = this.el.current.getBoundingClientRect();
      // difference between widths plus padding value
      const leftShift = (this.el.current.scrollWidth - this.el.current.clientWidth) / 2 + 20;

      const parameters = {
        content: this.el.current.innerText,
        top: rect.bottom,
        left: rect.left - leftShift,
        parent: this.el.current,
      };
      //console.log('hint', parameters);
      this.props.setParameters(parameters);
    }
  };

  mouseLeave = (e) => {
    if (this.state.overflow) {
      const widget = document.getElementById('widget-popup');

      if (e.relatedTarget !== widget) {
        //console.log('hint reset');
        clearTimeout(this.hoverDelay);
        this.props.resetParameters();
      }
    }
  };

  toClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log('clip');
    const s = this.props.children.toString();
    window.navigator.clipboard
      .writeText(s)
      .then(() => {
        console.log(s);
      })
      .catch((err) => {
        //console.log('Something went wrong', err);
      });
  };

  render() {
    const { clip, fullh, children, number, status, className, pr10, theme, align } = this.props;
    const clipReady = clip && config.withClipboard;
    return (
      <GridCell
        align={align}
        ref={this.el}
        className={className}
        theme={theme}
        status={status}
        $number={number}
        $pr10={pr10}
        $fullh={fullh}
        //innerRef={this.setCellRef}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        {children}
        {clipReady && <ToClip title="Скопировать в буфер обмена" className="toclip" onClick={this.toClipboard} />}
      </GridCell>
    );
  }
}

export const Cell = connect(null, {
  setParameters,
  resetParameters,
})(CellCmp);

export const StatusCell = styled(Cell)`
  padding-left: 15px;
  &::before {
    left: 0 !important;
  }
`;

import { useEffect, useState, useRef } from 'react';
import { change } from 'redux-form';

import { Wrapper, Popup } from './Autocomplete2.styled';

export const Autocomplete2 = (props) => {
  const { label, getList, input, className, placeholder, disabled, onSelectItem, selectedName } = props;
  const [list, setList] = useState([]);
  const [popup, setPopup] = useState(false);
  const [value, setValue] = useState(selectedName);
  const [selectedValue, setSelectedValue] = useState(selectedName);
  const inputRef = useRef();

  // init
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // get list
  useEffect(() => {
    getList(value).then((list) => {
      setList(list);
      if (value && selectedValue !== value) {
        setPopup(true);
      }
    });
  }, [value, selectedName]);

  // handle outside
  const handleOutsideClick = (e) => {
    if (inputRef.current) {
      if (!inputRef.current.contains(e.target)) {
        if (selectedValue !== value) {
          if (value) {
            setValue(selectedValue);
          } else {
            setSelectedValue(value);
            input.onChange(null);
          }
        }
        setPopup(false);
      }
    }
  };

  // get elements
  const getItemsEl = () => {
    //const { name } = input;
    //const { dispatch, form } = meta;

    // empty
    if (!list.length) {
      return null;
    }

    return list.map((item, idx) => {
      return (
        <li
          key={idx}
          onClick={() => {
            if (onSelectItem) {
              onSelectItem(item);
            }
            input.onChange(item.id);
            setValue(item.name);
            setSelectedValue(item.name);
            //return dispatch(change(form, name, item.name));
          }}
        >
          {item.name}
        </li>
      );
    });
  };

  const itemsEl = getItemsEl();
  const name = input.name;

  return (
    <Wrapper className={className}>
      {label && <label>{label}</label>}
      <input {...input} type="hidden" />
      <input
        name={name + '_'}
        ref={inputRef}
        type="text"
        autoComplete="off"
        disabled={disabled}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={(event) => {
          const val = event.target.value;
          if (!val) {
            setSelectedValue(val);
            input.onChange(val);  
          }
          setValue(val);
          //input.onChange(event.target.value);
        }}
        onFocus={() => {
          setPopup(true);
        }}
      />
      {popup && itemsEl && (
        <Popup>
          <ul>{itemsEl}</ul>
        </Popup>
      )}
    </Wrapper>
  );
};

import { useState, useEffect } from 'react';

import { prepareFilter } from 'libs/common';
import { getVehicleClasses, getCategories } from 'services/types';
import { getTariffsDict } from 'services/payments-service';
import { Loader } from 'libs/ui';
import { Controller } from 'libs/control';
import { PlateGrid } from './grid';
import Filter from './filter';

export const Plate = (props) => {
  const [items, setItems] = useState({});
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [sortParams, setSortParams] = useState([{ field_name: 'pan', direction: 'asc' }]);
  const [tariffs, setTariffs] = useState(null);

  // tariffs
  useEffect(() => {
    getTariffsDict().then((data) => setTariffs(data));
  }, []);

  if (!tariffs) {
    return <Loader />;
  }

  // classes
  const vehicleClasses = getVehicleClasses();
  const categories = getCategories();

  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    //console.log('🚀 ~ prepareParams ~ data:', data);
    const intFields = [];
    const boolFields = ['is_blocked'];
    const addParams = {};
    if (props.clientId) {
      addParams.client_id = props.clientId;
    }
    return prepareFilter(data, addParams, { boolFields, intFields });
  };

  // grid actions
  const gridActions = {
    addEntries: (data) => setItems({ ...items, ...data }),
    clearEntries: () => setItems({}),
    setSortParams: (data) => setSortParams(data),
    setOffset: (val) => setOffset(val),
    setCount: (val) => setCount(val),
  };

  // grid params
  const gridParams = {
    entries: items,
    offset,
    count,
    sortParams,
  };

  return (
    <Controller
      {...props}
      {...gridParams}
      {...gridActions}
      type="plates"
      filterName="platesFilter"
      grid={PlateGrid}
      gridFilter={Filter}
      getRequestParams={prepareParams}
      // additional data
      tariffs={tariffs}
      vehicleClasses={vehicleClasses}
      categories={categories}
    />
  );
};

// change urls for development env
const test = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_DEV === '1';
if (test) {
  console.log('env: test');
  if (window.location.href.indexOf('localhost') !== -1) {
    window.local = true;

    //window.config.use_subscription_balance = true;

    // urengoy
    // window.config.network.api_url = 'https://crm.urengoy.stage.caps.smprc.ru/api';

    // stockmann
    // window.config.network.api_url = 'https://crm.stockmann.stage.caps.smprc.ru/api';
    // window.config.network.additional_reports_url = 'http://10.105.0.117:82';
    window.config.network.additional_reports_url = [
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report1.rptdesign', title: 'Время присутствия' },
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report2.rptdesign', title: 'Время присутствия без оплаты' },
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report3.rptdesign', title: 'Распределение оплат и передвижений' },
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report4.rptdesign', title: 'Распределение оплат и передвижений (отчет)' },
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report5.rptdesign', title: 'Распределение оплат и передвижений без оплат' },
      { link: 'http://10.100.62.208:8080/birt/frameset?__report=report6.rptdesign', title: 'Распределение оплат и передвижений без оплат краткий' },
    ];

    // tumen
    // window.config.network.api_url = 'https://crm.tumen.stage.caps.smprc.ru/api';

    // igora
    // window.config.network.api_url = 'https://crm.igora.stage.caps.smprc.ru/api';

    // parma menskings
    // window.config.network.api_url = 'http://10.105.0.160/api';

    // simf
    // window.config.network.api_url = 'https://crm.simferopol.stage.caps.smprc.ru/api';

    // gcup
    // window.config.network.api_url = 'https://crm.gcup.stage.caps.smprc.ru/api';

    // stockman
    window.config.network.api_url = 'https://crm.stockmann.stage.caps.smprc.ru/api';

    // prod gcup
    // window.config.network.api_url = 'http://10.15.252.234:3080/api';

    // prod astrus
    // window.config.network.api_url = 'http://192.168.171.201/api';

    // gcup central
    // window.config.network.api_url = 'http://10.131.1.131/api';
    // window.config.withClipboard = true;
  }
}

window.isObjectEmpty = function (obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

window.formatNumber = function (n) {
  return new Intl.NumberFormat('ru-Ru').format(n);
};

export const config = window.config;

export default config;

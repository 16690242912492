import React, { useState, useEffect } from 'react';
//import styled from 'styled-components'

import { user, data } from 'services';
import { Select } from './fields';
import { getRacks } from 'services/types';

export const SourceFilter = (props) => {
  const [source, setSource] = useState('');
  const [racks, setRacks] = useState([]);

  const getItemsAll = () => {
    const dict = data.getDict();
    if (!dict) {
      return [
        { name: 'Сервер', value: 'rack.server' },
        { name: 'Касса', value: 'rack.cashbox' },
        { name: 'АПП', value: 'rack.payment' },
        { name: 'Стойка', value: 'rack.lane' },
      ];
    }

    if (props.sources) {
      return dict[props.sources].items;
    }

    return dict.source_report.items;
  };

  const itemsAll = getItemsAll();

  const racks0 = data.getRacks();

  useEffect(() => {
    // get racks for filter
    if (!racks0) {
      getRacks(
        props.parkingId,
        (items) => {
          data.setRacks(items);
          setRacks(items);
        },
        []
      );
    } else {
      setRacks(racks0);
    }
  }, [props.parkingId]);

  const filter = (type, out) => {
    if (!source || source === type) {
      let items = racks.filter((el) => el.type === type);

      if (props.parkingIdLocal) {
        items = items.filter((el) => el.parking_id === props.parkingIdLocal);
      }
      //console.log('🚀 ~ filter ~ items', items);
      const sortKey = 'mapped_name';
      items
        .sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return 1;
          }
          if (a[sortKey] < b[sortKey]) {
            return -1;
          }
          return 0;
        })
        .forEach((el) => {
          out.push(
            <option key={el.id} value={el.id}>
              {el.mapped_name}
            </option>
          );
        });
    }
  };

  /**
   * get options for source filter
   * @returns
   */
  const getSources = () => {
    const out = [];
    const exclude = props.exclude;
    const items = itemsAll.filter((el) => {
      if (exclude) {
        return !exclude.includes(el.value);
      }
      return true;
    });

    items.forEach((el) => {
      filter(el.value, out);
    });
    return out;
  };

  /**
   * change source
   * @param {*} data
   */
  const sourceChange = (data) => {
    setSource(data.target.value);
  };

  const getOptions = () => {
    const exclude = props.exclude;
    const items = itemsAll.filter((el) => {
      if (exclude) {
        return !exclude.includes(el.value);
      }
      return true;
    });

    const out = items.map((el, idx) => {
      return (
        <option key={idx + 1000} value={el.value}>
          {el.name}
        </option>
      );
    });
    return out;
  };

  const sources = getSources();
  const options = getOptions();

  return (
    <>
      <Select width="140px" name="source_type" type="select" label="Источник" onChange={sourceChange}>
        <option value="">Все</option>
        {options}
      </Select>

      <Select width="140px" name="source" type="select" label="№">
        <option value="">Все</option>
        {sources}
      </Select>
    </>
  );
};

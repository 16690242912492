import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  height: 30px;
  padding: 0 15px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  line-height: 0.8;
  border: none;
  outline: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 0px 30px 0 rgba(0, 0, 0, 0);
  transition: 0.3s all ease-out;

  font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
  display: ${(props) => (props.invisible ? 'none' : 'flex')};
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.bgColor};
  img,
  i {
    margin-right: 5px;
  }
  &:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 0px 30px 0 rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    cursor: not-allowed;
  }
  /* margin-right: ${(props) => (props.mr0 ? '0' : 'inherit')}; */
`;

// default theme
Button.defaultProps = {
  theme: {
    color: '#000',
    bgColor: '#dbdbdb',
  },
};

export const SquareButton = styled(Button)`
  height: 80px;
  width: 500px;
  padding: 0;
  border-radius: 4px;
  font-size: 26px;
  img,
  i {
    margin-right: 25px;
  }
`;
export const GradientButton = styled(Button)`
  background: ${(props) => props.theme.bg};
  border: solid 1px ${(props) => props.theme.border};
`;

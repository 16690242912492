import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { config } from 'config';
import { user, data } from 'services';
import { api } from 'api';
import { getRacks } from 'services/types';
import { GetXlsButton, ScrollUpButton, Loader } from 'libs/ui';

export const SortContext = React.createContext({
  setSort: () => {},
  getSortArrow: () => {},
});

class ControllerCmp extends React.Component {
  constructor(props) {
    super(props);

    this.onElementHeightChangeTimer = null;
    this.initialGettingEntriesTimer = null;
    this.initial = {};

    // select method
    this.subType = props.subType ? props.type : '';
    let method = props.type;
    this.isReport = ['utilization', 'statistics'].indexOf(method) !== -1;
    this.isRoles = ['roles'].indexOf(method) !== -1;
    if (this.isReport) {
      method = 'reports';
    } else if (props.subType) {
      method = 'payment_means';
    }
    this.method = method;

    this.state = {
      racks: [],
      dataIsLoading: false,
      documentHeight: null,
      showScrollUp: false,
      xlsInProcess: false,
      cnt: 0,
      summ: 0,
    };
  }

  componentDidMount() {
    this._mounted = true;

    if (data.getRacks() === null) {
      getRacks(
        this.props.parkingId,
        (items) => {
          data.setRacks(items);
          if (this._mounted) {
            this.setState({ racks: items });
          }
        },
        []
      );
    }

    this.getInitialEntriesHandler();

    this.setInitialScroll();
    this.scrollListner();
    this.updateDocumentHeight();

    this.onElementHeightChange(this.updateDocumentHeight);
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.initialGettingEntriesTimer) {
      clearTimeout(this.initialGettingEntriesTimer);
    }
    if (this.onElementHeightChangeTimer) {
      clearTimeout(this.onElementHeightChangeTimer);
    }
    window.onscroll = null;
  }

  getInitialEntriesHandler = () => {
    //console.log('getInitialEntriesHandler', this.props.filter);
    if (this.props.gridFilter) {
      //if (this.props.filter) {
      //this.getInitialEntries();
      //} else {
      this.initialGettingEntriesTimer = setTimeout(this.getInitialEntries, 300);
      //}
    } else {
      this.getInitialEntries();
    }
  };

  getInitialEntries = () => {
    //console.log('🚀 !!!!!!!!!!! getInitialEntries');
    //if (this.props.entries && Object.keys(this.props.entries).length === 0) {
    //if (this.props.setCount) {
    //    this.getEntriesCount()
    //}
    this.getEntries({ offset: 0 }, undefined, true);
    //}
  };

  // methods for sorting
  setSort = (name) => {
    let currentDirection = this.getSortDirection(name);
    let direction = currentDirection === 'desc' ? 'asc' : 'desc';

    let params = [
      {
        field_name: name,
        direction: direction,
      },
    ];
    this.props.setSortParams(params);
    this.getEntries({ offset: 0 }, params, true);
  };

  getSortArrow = (name) => {
    let direction = this.getSortDirection(name);
    if (direction === 'asc') {
      return <span className="fa fa-long-arrow-up" />;
    } else {
      return <span className="fa fa-long-arrow-down" />;
    }
  };

  getSortDirection(name) {
    let sortParams = this.props.sortParams;
    for (let i = 0; i < sortParams.length; i++) {
      if (sortParams[i].field_name === name) {
        return sortParams[i].direction;
      }
    }
  }

  /**
   * need for receiving data on scroll
   *
   * @memberof Controller
   */
  onElementHeightChange = (callback) => {
    let lastHeight = this.getDocumentHeight();
    let newHeight = null;

    const run = () => {
      newHeight = this.getDocumentHeight();
      if (lastHeight !== newHeight) {
        if (callback) callback();
        lastHeight = newHeight;
      }

      if (this.onElementHeightChangeTimer) {
        clearTimeout(this.onElementHeightChangeTimer);
      }
      this.onElementHeightChangeTimer = setTimeout(run, 500);
    };
    run();
  };

  updateDocumentHeight = () => {
    const height = this.getDocumentHeight();
    this.setState({ documentHeight: height });
  };

  getDocumentHeight = () => {
    return Math.max(
      document.body.offsetHeight,
      document.body.scrollHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight
    );
  };

  // methods for scrolling
  setInitialScroll = () => {
    let scrollPosition = this.props.interface ? this.props.interface.scrollPosition : 0;
    window.scrollTo(0, scrollPosition);
  };
  scrollToUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  scrollListner = () => {
    window.onscroll = () => {
      const scrollHeight = window.innerHeight + window.scrollY; //pageYOffset

      if (!this.state.showScrollUp && scrollHeight > document.documentElement.clientHeight) {
        this.setState({ showScrollUp: true });
      } else if (this.state.showScrollUp && scrollHeight === document.documentElement.clientHeight) {
        this.setState({ showScrollUp: false });
      }

      if (scrollHeight === this.state.documentHeight) {
        this.getEntries();
      }
    };
  };

  getFilter = () => {
    const reqParams = this.props.getRequestParams(this.props.filter);
    //console.log('🚀 ~ reqParams', reqParams, this.initial);
    return reqParams ? (Object.keys(reqParams).length ? reqParams : this.initial) : this.initial;
  };

  /**
   * save list to xls file
   *
   * @memberof Controller
   */
  getXls = () => {
    if (!this.state.xlsInProcess) {
      this.setState({ xlsInProcess: true }, () => {
        const filterParams = this.props.getRequestParams(this.props.filter);
        const sortParams = this.props.sortParams;
        if (this.subType) {
          filterParams.mean_type = this.subType;
        }

        api
          .call(this.method + '.get_xls', { filter_params: filterParams, sort_params: sortParams })
          .then((data) => {
            if (data) {
              this.setState({ xlsInProcess: false });
              window.open(config.network.xls_url + data, '_blank');
            }
          })
          .catch(() => {
            this.setState({ xlsInProcess: false });
          });
      });
    }
  };

  /**
   * generate button for xls with right support
   */
  getXlsLink = () => {
    const test = this.method === 'promise_pay' ? 'promise_pay.edit' : this.method + '.get_xls';
    if (!user.haveRight(test)) {
      return null;
    }
    return <GetXlsButton getXls={this.getXls} />;
  };

  /**
   * add xls link action
   * @param {*} actions []
   * @returns
   */
  getXlsAction = (actions) => {
    const test = this.method === 'promise_pay' ? 'promise_pay.edit' : this.method + '.get_xls';
    if (user.haveRight(test)) {
      actions.splice(0, 0, { cmp: <GetXlsButton key="getxls" getXls={this.getXls} /> });
    }
  };

  refresh = () => {
    this.getEntries({}, null, true);
  };

  /**
   * get entries with filter
   *
   * @memberof Controller
   */
  getEntries = (paggingParams, sortParams, refresh) => {
    const pageSize = 30;
    const out = {};
    let cnt = this.state.cnt;
    if (refresh) {
      this.props.clearEntries();
      out.cnt = 0;
      cnt = 0;
    }

    out.dataIsLoading = true;
    let newOffset = paggingParams ? paggingParams.offset : this.props.offset;
    let reqSortParams = sortParams ? sortParams : this.props.sortParams;
    //console.log(cnt, )
    if (cnt && newOffset >= cnt) {
      return;
    }

    this.setState(out);
    const filter = this.getFilter();
    const method = this.isReport ? this.method + '.' + this.props.type : this.method + '.list';
    if (this.subType) {
      filter.mean_type = this.subType;
    }

    let requestParams = {
      filter_params: filter,
      sort_params: reqSortParams,
      paging_params: {
        limit: pageSize,
        offset: newOffset,
      },
    };
    if (this.isReport) {
      requestParams = filter;
    }

    api
      .call(method, requestParams)
      .then((data) => {
        const out = { dataIsLoading: false };
        if (Array.isArray(data)) {
          this.formatItems(data, newOffset);
        } else if (Array.isArray(data.items)) {
          //console.log('formatItems')
          this.formatItems(data.items, newOffset);
          // cnt !== null - set cnt
          // cnt === null and items - second offset - not set cnt
          // cnt === null and !items - set cnt 0
          if (data.count !== null) {
            this.props.setCount(data.count);
            out.cnt = data.count;
          } else {
            if (!data.items.length) {
              //this.props.setCount(0);
              //out.cnt = 0;
            }
          }
          if (data.total !== undefined && data.total !== null) {
            //this.props.setSumm(data.total);
            const total = data.items.length ? (data.total === null ? undefined : data.total) : 0;
            out.summ = total;
            this.props.setSumm(total);
          } else {
            this.props.setSumm(0);
          }
        }
        this.setState(out);
      })
      .catch((data) => {
        if (data) {
          this.props.addEntries({});
        }
        if (this._mounted) {
          this.setState({ dataIsLoading: false, cnt: 0 });
        }
      });
  };

  formatItems = (data, newOffset) => {
    let entriesObj = {};
    this.props.setOffset(newOffset + data.length);

    if (this.isReport) {
      entriesObj = data;
    } else {
      data.forEach((item) => {
        // js engine sort object keys if key is number
        // and this is breaks sorting in grids
        // so we conversion id's to string
        if (!isNaN(parseFloat(item.id)) && isFinite(item.id)) {
          const key = `id-${item.id}`;
          entriesObj[key] = item;
        } else {
          entriesObj[item.id] = item;
        }
      });
    }

    //console.log('add entries', entriesObj)

    this.props.addEntries(entriesObj);

    // set count if reports
    if (this.isReport) {
      this.props.setCount(entriesObj.length);
    }
  };

  /**
   * get entries count
   *
   * @memberof Controller
   */
  getEntriesCount = () => {
    return;
    /*
    console.log('getEntriesCount')

    if (this.isReport) {
        return;
    }

    const filter = this.getFilter();
    const method = this.method + '.count';
    
    api.call(method, {filter_params: filter}).then(data => {
        this.props.setCount(data);
    }).catch(data => {
        data = 0;
        this.props.setCount(data);
    });
    */
  };

  /**
   * filter submit
   *
   * @memberof Controller
   */
  submit = () => {
    this.initial = {};
    this.getEntries({ offset: 0 }, null, true);
    this.getEntriesCount({ offset: 0 }, null, true);
  };

  /**
   * set initial values in filter comp
   *
   * @memberof Controller
   */
  setInitial = (values) => {
    //console.log('🚀 ~ setInitial', values);
    this.initial = Object.assign({}, values);
  };

  render() {
    const Filter = this.props.gridFilter || null;
    const Grid = this.props.grid;
    const Chart = this.props.chart;
    if (data.getRacks() === null) {
      return null;
    }

    return (
      <>
        {Filter && <Filter setInitial={this.setInitial} onSubmit={this.submit} {...this.props} />}

        <SortContext.Provider value={{ setSort: this.setSort, getSortArrow: this.getSortArrow }}>
          {this.props.chart && <Chart entries={this.props.entries} />}
          <Grid
            summ={this.state.summ}
            refresh={this.refresh}
            getEntries={this.getEntries}
            getEntriesCount={this.getEntriesCount}
            getXls={this.getXls}
            getXlsLink={this.getXlsLink}
            getXlsAction={this.getXlsAction}
            setSort={this.setSort}
            getSortArrow={this.getSortArrow}
            updateDocumentHeight={this.updateDocumentHeight}
            {...this.props}
          />
          {this.state.dataIsLoading && <Loader size="medium" height={'110px'} theme={this.props.theme} />}
          {this.state.showScrollUp && <ScrollUpButton src="/images/scroll_up_arrow.png" onClick={this.scrollToUp} />}
        </SortContext.Provider>
      </>
    );
  }
}

ControllerCmp.defaultProps = {
  subType: '',
};

const mapStateToProps = (state, props) => {
  const filterName = props.filterName || `${props.type}Filter`;
  const filterValues = getFormValues(filterName)(state);
  //console.log('🚀 ~ mapStateToProps ~ filterValues', filterValues);
  return {
    filter: filterValues,
  };
};

export const Controller = connect(mapStateToProps)(ControllerCmp);

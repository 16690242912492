import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';

import { api } from 'api';
import { data } from './data';
import { user } from './user';

export const getSource2 = (source) => {
  const racksMap = data.getRacksMap();
  return racksMap ? racksMap[source] || source : source;
};

export const getDate = (date) => {
  if (date) {
    return moment(date)
      .format('lll')
      .replace(/[г., ]{4}/, '')
      .replace(/\./, '');
  }
};

export const getDateTime = (date, simple = false) => {
  if (!date) {
    return '';
  }

  if (simple) {
    return moment(date).format('lll');
  }

  const d = moment(date);
  let s = d.seconds();
  if (s < 10) {
    s = '0' + s;
  }

  s =
    d
      .format('lll')
      .replace(/[г., ]{4}/, '')
      .replace(/\./, '') +
    ':' +
    s;

  return s;
};

export const getMeanStatus = (mean) => {
  const out = [];
  if (mean.is_mean_blocked) {
    if (mean.is_suspicious) {
      out.push(<img title="Подозрительный проезд" className="td-icon" key="ms1" src="/images/red_lock_icon.png" alt="" />);
    } else {
      out.push(<img title="Заблокированный проезд" className="td-icon" key="ms2" src="/images/black_lock_icon.png" alt="" />);
    }
  }
  if (mean.is_handicapped) {
    out.push(<img title="Льгота" className="td-icon td-icon--big" key="ms3" src="/images/tax-free.png" alt="" />);
  }

  return !out.length ? null : out;
};

export const getAmount = (amount) => {
  let out = '';
  if (amount && Number.isInteger(amount)) {
    out = amount / 100;
  } else {
    out = '0';
  }
  return out + ' ₽';
};

/**
 * get string duration
 * @param {*} duration  sec
 * @returns
 */
export const getDuration = (duration) => {
  if (!duration) {
    return '';
  }

  let durationInSec = duration;

  if (durationInSec >= 86400) {
    let days = Math.floor(durationInSec / 86400);
    let remainderHours = durationInSec % 86400;
    let hours = Math.floor(remainderHours / 3600);
    let remainderMinutes = remainderHours % 3600;
    let minutes = Math.floor(remainderMinutes / 60);
    let seconds = remainderMinutes % 60;

    return `${days}д ${hours}ч ${minutes}м ${seconds}c`;
  } else if (durationInSec >= 3600) {
    let hours = Math.floor(durationInSec / 3600);
    let remainderMinutes = durationInSec % 3600;
    let minutes = Math.floor(remainderMinutes / 60);
    let seconds = remainderMinutes % 60;

    return `${hours}ч ${minutes}м ${seconds}c`;
  } else {
    let minutes = Math.floor(durationInSec / 60);
    let seconds = durationInSec % 60;

    return `${minutes}м ${seconds}c`;
  }
};

export const getParking = (id) => {
  return user.getMaps().parkingMap[id] ? user.getMaps().parkingMap[id] : '';
};

export const getRacks = (stay_id, onSuccess, type = ['rack.cashbox', 'rack.payment']) => {
  api
    .call('racks.list', {
      filter_params: { type: type, parking_id: stay_id },
      sort_params: [{ field_name: 'id', direction: 'asc' }],
      paging_params: {},
    })
    .then((data) => {
      //console.log('🚀 ~ ', data);
      onSuccess(data);
    });
};

export const getPaymentRacks = () => {
  return api.call('dictionaries.racks_with_payment', {});
};

export const getPrintableRacks = () => {
  return api.call('dictionaries.racks_with_printable', {});
};

export const getActiveZones = () => {
  return api.call('dictionaries.zones_with_entry', {});
};

export const getRackName = (source) => {
  return data.getRacksMap()[source] || source;
};

export const getShortRFID = (rfid) => {
  const max1 = 6;
  if (rfid.length < max1) {
    return rfid;
  }
  return rfid.substring(rfid.length - max1);
};

/**
 * get meantype by rackId
 * @param {*} id
 * @returns
 */
export const getMeanType = (id) => {
  return data.getDictValue('stay_identity', id);
};

/**
 * get meantype in 3d form
 * @param {*} id
 * @returns
 */
export const getMeanType3 = (id) => {
  const meanTypes3 = {
    bank_card: 'Банк карту',
  };

  if (meanTypes3[id]) {
    return meanTypes3[id];
  }

  return data.getDictValue('stay_identity', id);
};

/**
 * get tariffication type
 */
export const getTarrificatorType = (type) => {
  const testType = type ? type : user.getTarifficator();
  //caps_cs_backend.tarifficator.gcup.GCUPTarifficator
  return testType === 'TyumenTarifficator'
    ? 'tumen'
    : testType === 'MenskingsTarifficator'
    ? 'menskings'
    : testType === 'IgoraTarifficator'
    ? 'igora'
    : testType === 'ParmaTarifficator'
    ? 'parma'
    : testType === 'GCUPTarifficator' || testType === 'caps_cs_backend.tarifficator.gcup.GCUPTarifficator'
    ? 'two_periods'
    : 'default';
};

/**
 * get tariff duration
 * @param {*} time sec
 * @returns
 */
export const getTariffDuration = (time) => {
  if (time === 0) {
    return '0 мин';
  }

  if (!time) {
    return '';
  }

  let durationInMin = time;
  if (durationInMin >= 1440) {
    let days = Math.floor(durationInMin / 1440);
    let remainderHours = durationInMin % 1440;

    let hours = Math.floor(remainderHours / 60);
    let remainderMinutes = remainderHours % 60;

    let minutes = remainderMinutes;

    return `${days}д ${hours}ч ${minutes}м`;
  } else if (durationInMin >= 60) {
    let hours = Math.floor(durationInMin / 60);
    let remainderMinutes = durationInMin % 60;

    let minutes = remainderMinutes;

    return `${hours}ч ${minutes}м`;
  } else {
    let minutes = durationInMin;

    return `${minutes}м`;
  }
};

/**
 * get vehicle classes
 * @returns map
 */
export const getVehicleClasses = () => {
  return user.getMaps().vehicle_class;
};

/**
 * get vehicle categories
 * @returns
 */
export const getCategories = () => {
  return user.getMaps().vehicle_category;
};

/**
 * get options list from data
 * @param {*} items {id, name}
 * @returns
 */
export const getOptionsList = (items) => {
  if (!Array.isArray(items)) {
    return null;
  }
  return items.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
};

/**
 * get cats list
 */
export const getCategoriesList = () => {
  const items = getCategories();
  return getOptionsList(items);
};

/**
 * get classes list
 */
export const getVehicleClassesList = () => {
  const items = getVehicleClasses();
  return getOptionsList(items);
};

/**
 * get first vc id
 */
export const getFirstVehicleClass = () => {
  const items = getVehicleClasses();
  if (!items) {
    return null;
  }
  return items[0].id;
};

/**
 * get category title by id
 */
export const getVehicleClassById = (id) => {
  if (!id) {
    return '';
  }
  id = id.toString();
  const items = getVehicleClasses();
  if (!items) {
    return '';
  }
  const cat = items.find((el) => el.id === id);
  return cat?.name;
};

/**
 * get first cat id
 */
export const getFirstCategory = () => {
  const items = getCategories();
  if (!items || !items.length) {
    return null;
  }
  return items[0].id;
};

/**
 * get category title by id
 */
export const getCategoryById = (id) => {
  if (!id) {
    return '';
  }
  id = id.toString();
  const items = getCategories();
  if (!items) {
    return '';
  }
  const cat = items.find((el) => el.id === id);
  return cat?.name;
};

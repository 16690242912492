import { api } from 'api';

const ADD_CATEGORIES = '/pages/options/categories/ADD_CATEGORIES';
const SET_OFFSET = '/page/options/categories/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/options/categories/SET_SORT_PARAMS';
const SET_CATEGORY_INFO = '/pages/options/categories/SET_CATEGORY_INFO';
const CLEAR_ENTRIES = '/pages/options/categories/CLEAR_ENTRIES';

export default function reducer(state, action) {
  const defaultState = {
    categories: {},
    offset: 0,
    count: 0,
    categoryInfo: null,
    sortParams: [{ field_name: 'name', direction: 'desc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        categories: {},
      };
    case ADD_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_CATEGORY_INFO:
      return {
        ...state,
        categoryInfo: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addCategories = (categories) => {
  return {
    type: ADD_CATEGORIES,
    data: categories,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setCategoryInfo = (value) => {
  return {
    type: SET_CATEGORY_INFO,
    data: value,
  };
};

export const getRequestParams = () => (dispatch, getState) => {
  return {};
};

export const addCategory = (params, callback) => (dispatch) => {
  api
    .call('vehicle_category.add', {
      item: params,
    })
    .then((res) => {
      if (callback) callback();
    });
};

export const updateCategory = (id, params, callback) => (dispatch) => {
  api
    .call('vehicle_category.update', {
      id: id,
      item: params,
    })
    .then((res) => {
      if (callback) callback();
    });
};

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { user } from 'services';
import { required, ErrorMsg, StyledField } from 'libs/form';
import { ButtonContainer, Submit } from 'libs/ui';

function NaturalPersonForm(props) {
  const useLogin = user.canUserLogin();
  const rightUpdate = user.haveRight('clients.update');

  return (
    <form onSubmit={props.handleSubmit} autoComplete="new-password">
      <ErrorMsg theme="dark" message={props.error} />

      <StyledField validate={required} type="text" label="ФИО" name="name" placeholder="Иванов Иван Иванович" />
      <StyledField type="date" label="Дата рождения" name="birth_date" placeholder="1990-09-09" className="shortfield" />
      <StyledField type="text" label="Паспорт" name="passport" placeholder="04 05 990440" />
      <StyledField type="text" label="Кем выдан" name="passport_issued_by" placeholder="Выдан УВД Приморского района г. Санкт-Петербург" />
      <StyledField type="date" label="Дата выдачи" name="passport_when_issued" placeholder="2004-09-09" className="shortfield" />
      <StyledField type="text" label="Код подразделения" name="passport_department_code" placeholder="233 – 005" />
      {useLogin && (
        <>
          {/*<StyledField type="text" label="Логин" name="login" placeholder="" />*/}
          <StyledField type="text" label="Пapоль ЛК" name="lkpwd" noaf={true} />
          <StyledField className="checkbox1" type="checkbox" label="Безлимитный" name="lk_unlimited" />
        </>
      )}
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

const getDecorateValues = (values) => {
  let decorateValues = {};
  for (let key in values) {
    if (values[key]) {
      decorateValues[key] = values[key];
    }
  }
  return decorateValues;
};

export default connect((store) => {
  let { type, name, birth_date, passport, passport_issued_by, passport_when_issued, passport_department_code, data } = store.client.clientInfo;
  let { lk_unlimited, lk_login } = data ? data : { lk_unlimited: '', lk_login: '' };

  const initialValues = getDecorateValues({
    type,
    name,
    birth_date,
    passport,
    passport_issued_by,
    passport_when_issued,
    lk_unlimited,
    lk_login,
    passport_department_code,
  });

  return {
    initialValues: initialValues,
  };
})(
  reduxForm({
    form: 'NaturalPersonForm',
  })(NaturalPersonForm)
);

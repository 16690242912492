import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm, FormSection, Form, SubmissionError } from 'redux-form';

import { data } from 'services';
import { routes } from 'router';
import { getTarrificatorType, getCategories, getVehicleClasses } from 'services/types';
import { HeadLineWithMargin, Card, GeneralInfo, DataWrapper } from 'modules/components/card/styledComponents';
import NewTariffForm from './forms/NewTariff';
import Warning from 'modules/components/warnings';
import { PageWrapper } from 'modules/components/pageComponents';
import { CloseCardButton } from 'libs/ui';
import { HeaderText, FormHeader, SaveButton, TariffManageWrapper, TariffData } from '../styledComponents';
import { addTariff, removeTariffInfo } from 'store/ducks/models/tariff';
import { setZone, removeZoneInfo, addMessageDisplayHandler } from 'store/ducks/interface/tariffCard';
import { Tarrificator } from './tarifficators/tarifficator';

class NewTariffCard extends Component {
  constructor() {
    super();

    this.state = {
      type: 'individual',
      showValidationError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error === 'duplicate') {
        if (this.props.error) {
          this.setState({ showValidationError: true });
        } else {
          this.setState({ showValidationError: false });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.removeTariffInfo();
  }

  /**
   * save individual tariff
   * @param {*} values
   * @returns
   */
  submit = (values) => {
    const tarifficatorType = getTarrificatorType();

    // clone
    const decorateParams = JSON.parse(JSON.stringify(values));
    if (decorateParams.time_to_leave) {
      decorateParams.time_to_leave *= 60;
    }
    if (decorateParams.secondentry_interval) {
      decorateParams.secondentry_interval *= 60;
    }

    const decorateZones = decorateParams.zones;

    // zones
    if (decorateZones) {
      switch (tarifficatorType) {
        // two periods tariffication
        case 'two_periods':
          const newZones = Object.assign({}, data.getDefaultZones());
          for (let zone in decorateZones) {
            const periods = decorateZones[zone].periods;
            periods.forEach((period, index) => {
              newZones[zone].periods[index].amount = parseInt(period.amount) * 100;
            });
          }
          decorateParams.zones = newZones;
          break;

        // default tariffication
        default:
          for (let zone in decorateZones) {
            // fix price
            if (decorateZones[zone].fixprice) {
              decorateZones[zone].fixprice *= 100;
            }
            const periods = decorateZones[zone].periods;

            // loop
            let loop;
            if (decorateZones[zone].loop !== undefined) {
              loop = parseInt(decorateZones[zone].loop);
              delete decorateZones[zone].loop;
            }

            let periodStartAt = 0;
            periods.forEach((period, index, array) => {
              period.amount *= 100;
              period.period *= 60;
              if (period.count) {
                period.count = parseInt(period.count);
              }
              if (index === 0) {
                period.start_at = periodStartAt;
              } else {
                periodStartAt += array[index - 1].period * (array[index - 1].count ? array[index - 1].count : 1);
                period.start_at = periodStartAt;
              }

              // loop
              if (loop !== undefined) {
                period.loop = loop === index;
              }

              delete period.id;
            });
          }
          break;
      }
    }

    if (decorateParams.vehicle_category) {
      decorateParams.vehicle_category = decorateParams.vehicle_category.map((el) => Number(el));
    } else {
      decorateParams.vehicle_category = [];
    }
    if (decorateParams.vehicle_class) {
      decorateParams.vehicle_class = decorateParams.vehicle_class.map((el) => Number(el));
    }

    // test
    const test = false;
    if (test) {
      console.log('🚀 ~ new tariff:', decorateParams);
      //const submissionErrors = {
      //  'zones.P1.periods[0].period': 'required'
      //};
      //submissionErrors._error = 'required';
      //throw new SubmissionError({ ...submissionErrors });
      return Promise.resolve(true);
    }

    return this.props.addTariff(decorateParams, this.afterSubmit).then((errors) => {
      // console.log('🚀 ~ returnthis.props.addTariff ~ errors:', errors);
      const submissionErrors = {};
      if (Array.isArray(errors)) {
        errors.forEach((element) => {
          if (element.field) {
            submissionErrors[element.field] = element.type;
            submissionErrors._error = errors.type;
          }
          if (element.param) {
            submissionErrors[element.param] = element.message;
            submissionErrors._error = errors.message;
          }
        });
      } else {
        submissionErrors[errors.field] = errors.type;
        submissionErrors._error = errors.type;
      }

      throw new SubmissionError({ ...submissionErrors });
    });
  };

  openTariffCard = (tariffId) => {
    this.props.history.push(`${routes.tariff}?id=${tariffId}`);
  };

  afterSubmit = (id) => {
    this.props.addMessageDisplayHandler(true);
    this.openTariffCard(id);
  };

  closeCard = () => {
    this.props.history.push(routes.tariffs);
  };

  // render
  render() {
    const { info, selectedZone, setZone, tariffication, zones } = this.props;
    const { fieldsDisable } = this.state;

    if (!info) {
      return null;
    }

    const categories = getCategories();
    const vehicleClasses = getVehicleClasses();

    const tarrificatorParams = { info, selectedZone, setZone, zones, tariffication, disabled: fieldsDisable, newIndividual: true };

    return (
      <PageWrapper>
        <HeadLineWithMargin>
          <p>Карточка тарифа</p>
          <CloseCardButton close={this.closeCard} />
        </HeadLineWithMargin>
        <Card>
          <GeneralInfo>
            <HeaderText>Новый тариф</HeaderText>
          </GeneralInfo>
          <DataWrapper>
            <TariffManageWrapper>
              <Form onSubmit={this.props.handleSubmit(this.submit)}>
                <TariffData>
                  <FormHeader>Данные тарифа</FormHeader>
                  <NewTariffForm categories={categories} vehicleClasses={vehicleClasses} type={this.state.type} />
                </TariffData>

                <FormSection name="zones">
                  <Tarrificator {...tarrificatorParams} />
                </FormSection>

                <SaveButton disabled={this.props.submitting} />
              </Form>
            </TariffManageWrapper>
          </DataWrapper>
        </Card>
        {this.state.showValidationError ? (
          <Warning
            type="error"
            width="480px"
            header="Тариф не сохранен"
            mainText="Проверьте настройку периодов по всем оставьте значения и сохраните ещё раз"
            cancelButton={{
              func: () => this.setState({ showValidationError: false }),
              text: 'ОК',
            }}
          />
        ) : null}
      </PageWrapper>
    );
  }
}

const mapStateToProps = (store) => {
  const out = {
    info: store.tariff.info || {},
    selectedZone: store.tariffCard.selectedZone,
  };

  out.zones = data.getDefaultZones();

  return out;
};

export default connect(mapStateToProps, {
  addTariff,
  removeTariffInfo,
  setZone,
  removeZoneInfo,
  addMessageDisplayHandler,
})(
  reduxForm({
    form: 'newTariffForm',
    initialValues: {
      type: 'individual',
      time_to_leave: 0,
      time_from: moment(),
    },
  })(withRouter(NewTariffCard))
);

//import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class Logic {
  token = '';
  lastMethod = '';
  packet;
  loginMethod;
  notify;
  storage;
  debug = false;
  unsetAuth;
  getError;

  constructor({ getError, unsetAuth, packet, loginMethod, notify, storage, debug }) {
    this.packet = packet;
    this.loginMethod = loginMethod;
    this.notify = notify;
    this.storage = storage;
    this.debug = debug;
    this.unsetAuth = unsetAuth;
    this.getError = getError;

    this.token = this.storage.getItem('token');
  }

  /**
   * logout user
   */
  logout = () => {
    this.storage.setItem('token', '');
    this.token = '';
    this.unsetAuth();
  };

  /**
   * get token
   * @returns
   */
  getToken() {
    if (!this.token) {
      this.token = this.storage.getItem('token');
    }
    return this.token;
  }

  /**
   * set auth token if needed
   * @param {*} req
   */
  setAuth = (req) => {     
    if (this.lastMethod !== this.loginMethod) {
      const token = this.getToken();
      if (token) {
        if (req.headers) {
          req.headers['authorization'] = 'Bearer ' + token + '';
        }
      }
    }
    return req;
  };

  /**
   * prepare request
   * @param req
   * @returns
   */
  prepareRequest = (req) => {
    //this.token = this.storage.getItem('token');
    this.lastMethod = req.url;

    // packet
    req.data = this.packet.createPacket(req.url, req.data);
    req.url = '';

    // auth
    return this.setAuth(req);
  };

  /**
   * prepare response
   * @param res
   * @returns
   */
  prepareResponse = (res) => {
    const isLoginMethod = this.lastMethod === this.loginMethod;
    const debugAuth = this.debug && isLoginMethod && false;
    const out = debugAuth
      ? {
          packet: {
            token:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJsb2dnZWRfaW4iOjE2NzAzMjA2MjguMzcyMTg5NX0.n31TpHXkzx0EyesCTIM0QWAy1qKmJFV1pOU71SI_uIU',
          },
          error: false,
        }
      : this.packet.decodePacket(res, isLoginMethod);

    // console.log('🚀 ~ out:', out);
    if (out.error) {
      if (out.is401) {
        this.logout();
      }
      //this.prepareError()
      //console.log('🚀 ~ out.packet:', out.packet);
      return Promise.reject(this.getError(out.packet));
    } else {
      if (isLoginMethod) {
        //console.log('🚀 ~ isLoginMethod', out);
        this.storage.setItem('token', out.packet.token);
        this.token = out.packet.token;
      }
    }
    //console.log('🚀 ~ out', out);
    return out.packet;
  };

  /**
   * prepare error
   * @param error
   * @returns
   */
  prepareError = (error) => {
    console.log('🚀 ~ error:', error);
    let msg = this.packet.getError(error);
    if (!error.response || (error.response && error.response.status !== 400)) {
      this.notify('Ошибка соединения', msg);
    }
    //console.log('🚀 ~ prepareError:', msg);
    return Promise.reject(msg);
  };
}

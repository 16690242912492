import { useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { getOptionsList } from 'services/types';
import { ResetBtn, UseBtn } from 'libs/ui';
import { Container, Input, ParkingFilter, DictFilter, Select, ButtonsContainer, DoubleField, DateSelect, DateTime } from 'libs/form';

const initial = {};

const Filter = (props) => {
  const { setInitial, reset, handleSubmit, change, theme, categories, vehicleClasses } = props;

  // did mount
  useEffect(() => {
    setInitial(initial);
    return () => {
      reset();
      setInitial(initial);
    };
  }, []);

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit}>
        <DictFilter w={140} dict="payment_mean.status" />

        <Input width="170px" name="mean_id" type="text" label="Номер" placeholder="3786 4678 6467 5656" />

        <Input width="220px" name="owner" type="text" label="Держатель" placeholder="Введите текст для поиска" />

        <Select width="140px" name="vehicle_category" type="select" label="Категория ТС">
          <option value="">Все</option>
          {getOptionsList(categories)}
        </Select>

        <Select width="140px" name="vehicle_class" type="select" label="Класс ТС">
          <option value="">Все</option>
          {getOptionsList(vehicleClasses)}
        </Select>

        <DateSelect from_name="start_at_from" to_name="start_at_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="start_at_from" type="dateTime" label="Дата действия с" withoutIcon />
            <DateTime width="160px" name="start_at_to" type="dateTime" withoutIcon />
          </DoubleField>
        </DateSelect>

        <DateSelect from_name="end_at_from" to_name="end_at_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="end_at_from" type="dateTime" label="Дата действия по" withoutIcon />
            <DateTime width="160px" name="end_at_to" type="dateTime" withoutIcon />
          </DoubleField>
        </DateSelect>

        <Input width="300px" name="tariff" type="text" label="Тариф" placeholder="Имя тарифа" />

        <Input label="Комментарий" width="370px" name="comment" type="text" placeholder="Текст комментария" />

        <Input width="220px" name="vehicle_plate" type="text" label="ГРЗ" placeholder="Введите текст для поиска" lastField />

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'subscriptionsFilter',
  destroyOnUnmount: false,
  initialValues: initial,
})(Filter);

import styled from 'styled-components';

export const TableFooter = styled.div`
  padding: 15px 0;
`;

export const TableCmp = styled.table`  
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`;

export const TableBody = styled.tbody`  
`;

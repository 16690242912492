import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { user } from 'services';
import { UpdateMessage, GradientButton, themes, CloseCardButton } from 'libs/ui';
import Warning from 'modules/components/warnings';
import { PageWrapper, Headline } from 'modules/components/pageComponents';
import { StyledGrid, Card, GeneralInfo } from 'modules/components/card/styledComponents';
import PersonalData from './PersonalData';
import ContactFace from './ContactFace';
import RfidWrapper from './rfid/Wrapper';
import EpmWrapper from './epm';
import PlatesWrapper from './plates';
import ZonesLimit from './ZonesLimit';
import ClientsGrid from '../grid/Grid';
import {
  ContactFaces,
  ContactFaceWrapper,
  Name,
  Type,
  Amount,
  DataTab,
  RfidTab,
  PlatesTab,
  EpmTab,
  ZonesTab,
  ProfileInfo,
  ProfileInfoData,
  ClientData,
  Tabs,
  StyledDataWrapper,
  ToggleBox,
  Toggle,
} from '../styledComponents';

import { routes } from 'router';

const StyledClientsGrid = styled(StyledGrid).attrs({ as: ClientsGrid })``;

const UpdateMessageBox = styled(UpdateMessage)`
  top: 480px;
  left: 33%;
`;
const AddContact = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px;
  line-height: 1.73;
  color: #3ea5ff;
  i {
    margin-right: 5px;
  }
`;

class ClientCard extends Component {
  state = {
    selectedTab: 'data',
    showWarning: false,
    showUpdateMessage: false,
  };

  componentDidMount() {
    if (this.props.interface.showMessageAfterAddClient) {
      this.removeMessageAfterAdd();
    }
  }

  removeMessageAfterAdd = () => {
    setTimeout(() => {
      this.props.addClientMessageDisplayHandler(false);
    }, 3000);
  };

  haveContacts = (contacts) => {
    return contacts && contacts.length === 0;
  };

  clientIsActive = (status) => {
    return status === 'active';
  };

  closeCard = () => {
    this.props.history.push(routes.clients);
  };

  toggleStatus = () => {
    if (this.clientIsActive(this.props.clientInfo.status)) {
      this.setState({ showWarning: true });
    } else {
      this.changeClientStatus();
    }
  };

  changeClientStatus = () => {
    let params = {
      id: this.props.clientInfo.id,
      status: this.clientIsActive(this.props.clientInfo.status) ? 'blocked' : 'active',
    };
    this.props.changeClientStatus(params, this.afterChangeStatus);
  };

  switchTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  closeWarning = () => {
    this.setState({ showWarning: false });
  };

  afterChangeStatus = () => {
    this.closeWarning();
    this.showMessage();
  };

  getFaceContacts = () => {
    if (this.props.clientInfo.contact) {
      let contact = this.props.clientInfo.contact;
      return (
        <ContactFace
          showMessageAfterAddContact={this.props.interface.showMessageAfterAddContact}
          addContactMessageDisplayHandler={this.props.addContactMessageDisplayHandler}
          key={contact.id}
          info={contact}
          updateClientContact={this.props.updateClientContact}
          getClientInfo={this.props.getClientInfo}
        />
      );
    }
  };

  showMessage = () => {
    this.setState({ showUpdateMessage: true });
    setTimeout(() => {
      this.setState({ showUpdateMessage: false });
    }, 3000);
  };

  getUpdateText = () => {
    const status = this.props.clientInfo.status;
    return this.clientIsActive(status) ? 'Клиент успешно разблокирован' : 'Клиент успешно заблокирован';
  };

  getPersonalData = () => {
    const clientType = this.props.clientInfo.type;
    const showMessageAfterAddClient = this.props.interface.showMessageAfterAddClient;

    if (clientType === 'natural_person')
      return (
        <PersonalData
          showMessageAfterAddClient={showMessageAfterAddClient}
          clientType={clientType}
          client_id={this.props.clientInfo.id}
          updateClientInfo={this.props.updateClientInfo}
        />
      );
    if (clientType === 'legal_person')
      return (
        <PersonalData
          showMessageAfterAddClient={showMessageAfterAddClient}
          clientType={clientType}
          client_id={this.props.clientInfo.id}
          updateClientInfo={this.props.updateClientInfo}
        />
      );
    if (clientType === 'sole_proprietor')
      return (
        <PersonalData
          showMessageAfterAddClient={showMessageAfterAddClient}
          clientType={clientType}
          client_id={this.props.clientInfo.id}
          updateClientInfo={this.props.updateClientInfo}
        />
      );
  };

  getClientType = () => {
    if (this.props.clientInfo.type) {
      const clientType = this.props.clientInfo.type;
      if (clientType === 'natural_person') return 'Физическое лицо';
      if (clientType === 'legal_person') return 'Юридическое лицо';
      if (clientType === 'sole_proprietor') return 'Индивидуальный предприниматель';
    }
  };

  toClientStays = () => {
    this.props.history.push(routes.stays, {
      filter: {
        client_name: this.props.clientInfo.name,
      },
    });
  };

  render() {
    const { balanceInfo, clientInfo } = this.props;
    const rightChangeStatus = user.haveRight('clients.change_status');
    const rightUpdate = user.haveRight('clients.update');
    const rightRfidList = user.haveRight('rfid.list');
    const rightZonesLimit = user.haveRight('clients.limits');
    const rightStaysList = user.haveRight('stays.list');
    const rightEpmList = user.haveRight('csc.list');
    const rightPlateList = user.haveRight('plates.list');

    return (
      <PageWrapper>
        <Headline>
          <p>{this.props.t('cards.Карточка клиента')}</p>
          <CloseCardButton close={this.closeCard} />
        </Headline>
        {this.props.clientInfo ? (
          <>
            <StyledClientsGrid hideTotal clients={[this.props.clientInfo]} />
            <Card>
              <GeneralInfo>
                <ProfileInfo>
                  <img width={100} src={`/images/${this.props.clientInfo.type}.png`} alt="" />
                  <ProfileInfoData>
                    <Name>{this.props.clientInfo.name}</Name>
                    <Type>{this.getClientType()}</Type>
                    <div>
                      {balanceInfo && (balanceInfo.balance || balanceInfo.balance === 0) ? <Amount>{`${balanceInfo.balance / 100}₽`}</Amount> : null}
                      <Amount>Номер счета: {this.props.clientInfo.account_id || 'Не известно'}</Amount>
                      {rightStaysList && (
                        <GradientButton theme={themes.darkGradient} onClick={this.toClientStays}>
                          Перейти в парковки
                        </GradientButton>
                      )}
                    </div>
                  </ProfileInfoData>
                </ProfileInfo>
                <Tabs>
                  <DataTab active={this.state.selectedTab} onClick={() => this.switchTab('data')}>
                    Данные
                  </DataTab>
                  {rightRfidList && (
                    <RfidTab active={this.state.selectedTab} onClick={() => this.switchTab('rfid')}>
                      RFID метки
                    </RfidTab>
                  )}
                  {rightEpmList && (
                    <EpmTab active={this.state.selectedTab} onClick={() => this.switchTab('epm')}>
                      БСК
                    </EpmTab>
                  )}
                  {rightPlateList && (
                    <PlatesTab active={this.state.selectedTab} onClick={() => this.switchTab('plates')}>
                      ГРЗ
                    </PlatesTab>
                  )}
                  {rightZonesLimit && (
                    <ZonesTab active={this.state.selectedTab} onClick={() => this.switchTab('zoneslimit')}>
                      Лимит ТС
                    </ZonesTab>
                  )}
                </Tabs>
                <ToggleBox isActive={this.clientIsActive(this.props.clientInfo.status)}>
                  <p>Активен</p>
                  <Toggle
                    disabled={!rightChangeStatus}
                    isActive={this.clientIsActive(this.props.clientInfo.status)}
                    onClick={rightChangeStatus ? this.toggleStatus : () => false}
                  >
                    <div></div>
                  </Toggle>
                  <p>Блокирован</p>
                </ToggleBox>
              </GeneralInfo>
              <StyledDataWrapper selectedTab={this.state.selectedTab}>
                {this.state.selectedTab === 'data' && (
                  <>
                    <div style={{ marginBottom: '1rem' }}>
                      <ClientData>{this.getPersonalData()}</ClientData>
                    </div>
                    <ContactFaceWrapper>
                      <ContactFaces>
                        {!this.props.clientInfo.contact && rightUpdate ? (
                          <AddContact onClick={this.props.showNewContactForm}>
                            <i className="fa fa-plus" />
                            Добавить контакт
                          </AddContact>
                        ) : null}
                        {this.props.interface.showNewContactForm ? (
                          <ContactFace
                            addContactMessageDisplayHandler={this.props.addContactMessageDisplayHandler}
                            info={this.props.newContactFace}
                            client_id={clientInfo.id}
                            addClientContact={this.props.addClientContact}
                            hideNewContactForm={this.props.hideNewContactForm}
                          />
                        ) : null}
                        {this.getFaceContacts()}
                      </ContactFaces>
                    </ContactFaceWrapper>
                  </>
                )}
                {this.state.selectedTab === 'rfid' && <RfidWrapper clientId={clientInfo.id} {...this.props} />}
                {this.state.selectedTab === 'epm' && <EpmWrapper clientId={clientInfo.id} {...this.props} />}
                {this.state.selectedTab === 'plates' && <PlatesWrapper clientId={clientInfo.id} {...this.props} />}
                {this.state.selectedTab === 'zoneslimit' && <ZonesLimit clientId={clientInfo.id} />}
              </StyledDataWrapper>
            </Card>
            {this.state.showUpdateMessage ? <UpdateMessageBox>{this.getUpdateText()}</UpdateMessageBox> : null}
            {this.state.showWarning ? (
              <Warning
                width="540px"
                header="Блокировка клиента"
                mainText={{
                  __html: `Блокировка клиента приведет к блокировке всех RFID меток
                  этого клиента.</br>
                  Заблокировать клиента ${this.props.clientInfo.name}?`,
                }}
                cancelButton={{
                  func: this.closeWarning,
                  text: 'Отменить',
                }}
                acceptButton={{
                  func: this.changeClientStatus,
                  text: 'Заблокировать клиента',
                  img: '/images/icon_lock.png',
                  theme: themes.red,
                }}
              />
            ) : null}
          </>
        ) : null}
      </PageWrapper>
    );
  }
}

export default withTranslation()(withRouter(ClientCard));

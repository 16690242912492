import styled from 'styled-components'

import { Headline } from '../pageComponents';
import { Container } from 'libs/ui'

export const Wrapper = styled(Container)`
  padding: 10px;
  background-color: #393e44;
  min-height: 100px;
  border-radius: 2px;
`
export const StyledGrid = styled.div`
  tbody th, tr {
    /*pointer-events: none;*/
  }
  tbody {
    &:last-child {
      border: solid 1px #393e44;
      td { border-bottom: 0px; }
    }
  }
  position: relative;
  top: 1px;
  width: 99.99%;
`
export const HeadLineWithMargin = styled(Headline)`
  margin-bottom: 35px;
`
export const Card = styled.div`
  border-radius: 2px;
`
export const GeneralInfo = styled.div`
  position: relative;
  height: 170px;
  background-color: #262b31;
  padding: 20px 20px 0 20px;
`
export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #393e44;
  min-height: 400px; //600
  padding: 25px 20px;
`
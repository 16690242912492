import React, { Component } from 'react';
import styled from 'styled-components';
import { change } from 'redux-form';

const Wrapper = styled.div`
  position: relative;
`;
const Popup = styled.div`
  max-height: 230px;
  overflow-y: auto;
  position: absolute;
  top: 38px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #bebebe;
  border-radius: 4px;
  z-index: 11;
  ul {
    li {
      padding: 5px;
      list-style: none;
      cursor: pointer;
      :hover {
        background-color: #3ea5ff;
        color: #fff;
      }
    }
  }
`;
// this component is not universal now
// getList metghod always pass value as 'full_name'
export class Autocomplete extends Component {
  constructor() {
    super();
    this.state = { list: [], showPopup: false };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);

    const { getList, setAutocompleteList, input } = this.props;
    //console.log('autocomplete', input.value)
    getList({ full_name: input.value }, {}, (list) => {
      //console.log('autocomplete', list);
      this.setState({ list: list });
      setAutocompleteList && setAutocompleteList(list);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.input) {
      if (this.input.contains(e.target)) {
        return;
      } else {
        this.setState({ showPopup: false });
      }
    }
  };

  getListItems = (list) => {
    const { name } = this.props.input;
    const { dispatch, form } = this.props.meta;

    return list.map((item) => {
      return (
        <li
          key={item.id}
          onClick={() => {
            if (this.props.onSelectItem) {
              this.props.onSelectItem(item);
            }
            return dispatch(change(form, name, item.full_name));
          }}
        >
          {item.full_name}
        </li>
      );
    });
  };

  getTariffList = (value) => {
    const { getList, setAutocompleteList } = this.props;
    const {
      requestParams: { filterParams, sortParams },
    } = this.props;
    //console.log('flt',filterParams, sortParams)
    if (value) {
      filterParams.name = value;

      getList(filterParams, sortParams, (list) => {
        //console.log('result', list)
        this.setState({ list: list });
        setAutocompleteList && setAutocompleteList(list);
      });
    } else {
      this.setState({ list: {} });
    }
  };

  render() {
    const { className, placeholder, disabled, input } = this.props;

    return (
      <Wrapper className={className}>
        {this.props.label ? <label>{this.props.label}</label> : null}
        <input
          ref={(input) => {
            this.input = input;
          }}
          {...input}
          type="text"
          autoComplete="off"
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event) => {
            input.onChange(event.target.value);
            this.getTariffList(event.target.value);
          }}
          onFocus={(event) => {
            this.setState({ showPopup: true });
          }}
        />
        {this.state.showPopup && Object.keys(this.state.list).length ? (
          <Popup>
            <ul>{this.getListItems(this.state.list)}</ul>
          </Popup>
        ) : null}
      </Wrapper>
    );
  }
}

import { getCategoryById, getVehicleClassById, getDate } from 'services/types';
import { ParkingCell } from 'libs/ui';
import { data } from 'services';

const withBalance = window.config.use_subscription_balance;

const getBalance = (__val, __params, row) => {
  const value = row.data?.balance;
  if (value === undefined) {
    return '';
  }

  return Math.round(value / 100);
};

const fields = [
  { name: 'is_blocked', title: 'Статус', width: 155, sortField: 'status', render: (val) => data.getDictValue('payment_mean.status', val) },
  { name: 'pan', title: 'Номер', width: 180, sortField: 'pan' },

  { name: 'balance', title: 'Баланс, ₽', width: 150, sortField: 'balance', enabled: withBalance, render: (val, params, row) => getBalance(val, params, row) },
  { name: 'name', title: 'Держатель', sortField: 'name' },
  { name: 'vehicle_plate', title: 'ГРЗ', width: 112, sortField: 'vehicle_plate' },
  { name: 'vehicle_category', title: 'Категория ТС', width: 112, sortField: 'vehicle_category', render: (val) => getCategoryById(val) },
  { name: 'vehicle_class', title: 'Класс ТС', width: 160, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) },
  { name: 'start_at', title: 'Дата с', width: 210, sortField: 'start_at', render: (val) => getDate(val) },
  { name: 'end_at', title: 'Дата по', width: 210, sortField: 'end_at', render: (val) => getDate(val) },
  { name: 'tariff', title: 'Тариф', width: 180 },
  { name: 'comment', title: 'Комментарий', width: 150 },
];

if (window.config.central) {
  fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
}

// get row class
const getRowClass = (row) => {
  let out = '';
  out += ' ' + row.status;
  return out;
};

export const tableParams = { fields, getRowClass, withComment: true };

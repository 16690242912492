import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { user } from 'services';
import Auth from 'pages/auth';
import { Layout } from 'libs/ui';
import { PublicRoute } from './public-route';
import { PrivateRoute } from './private-route';
import { accessLevelMap } from './access-level-map';
import { getSections, getAvailableSections, getSubsectionList } from './router-func';

export const Router = () => {
  /**
   * get routes
   * @returns
   */
  const getRoutes = () => {
    const sections = getAvailableSections();

    return sections.map((section, idx) => {
      let subsectionList = [];
      if (section.subsections) {
        subsectionList = getSubsectionList(section);
      }
      return (
        <PrivateRoute
          key={idx}
          path={section.path}
          component={section.component}
          params={{
            sectionName: section.name,
            subsectionList: subsectionList,
            subRoutes: section.subRoutes,
          }}
        />
      );
    });
  };

  const ready = !!user.info;

  const sections = ready && getSections();

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={Auth} />
        <PublicRoute path="/auth" component={Auth} />
        {ready && (
          <Layout sections={sections} accessLevelMap={accessLevelMap}>
            <Switch>
              {getRoutes()}
              <Redirect to="/auth" />
            </Switch>
          </Layout>
        )}
        <Redirect to="/auth" />
      </Switch>
    </BrowserRouter>
  );
};

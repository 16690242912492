import React, { Component } from 'react';

import { TableHeader } from 'libs/ui'
import Call from './Row';
import {
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

export default class StaysGrid extends Component {
  getCallsList = () => {
    const calls = this.props.entries || this.props.calls;
    let callsList = [];
    if (calls) {
      for (let key in calls) {
        callsList.push(<Call key={calls[key].id} {...calls[key]} />);
      }
    }
    return callsList;
  };

  render() {
    return (
      <GridBox>
        {this.props.hideTotal ? null : (
          <TopRow>
            <Total cnt={this.props.count} /> {/*this.props.getXlsLink()*/}
          </TopRow>
        )}
        <Grid className={this.props.className}>
          <TableHead>
            <TableHeadRow>
              <TableHeader width="110px">Тип</TableHeader>
              <TableHeader width="95px">Статус</TableHeader>
              <TableHeader width="210px">Время</TableHeader>
              <TableHeader width="185px">Длительность</TableHeader>
              <TableHeader width="115px">Оборудование</TableHeader>
              <TableHeader>№ устройства</TableHeader>
              <TableHeader width="155px">Послушать/скачать</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getCallsList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
